import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@utilitywarehouse/colour-system';
const useStyles = makeStyles(
  ({ spacing, breakpoints, palette, typography }) => ({
    root: {
      display: 'flex',
      alignItems: 'start',
      borderRadius: spacing(1),
      padding: spacing(2),
      fontSize: 24,
      backgroundColor: colors.purple900,
      color: palette.common.white,
      justifyContent: 'space-between',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(1),
    },
    heading: {
      fontSize: 22,
      lineHeight: '32px',
      [breakpoints.up('sm')]: {
        fontSize: 24,
      },
    },
    text: {
      lineHeight: '24px',
      '& $highlight': {
        fontWeight: typography.fontWeightMedium,
      },
    },
    image: {
      height: '120px',
      marginRight: spacing(-5.5),
      marginBottom: spacing(-2),
      [breakpoints.up('sm')]: {
        marginTop: spacing(-2),
      },
    },
    termsLink: {
      color: palette.common.white,
      textDecoration: 'underline',
      fontSize: 14,
      marginTop: 2,
    },
    highlight: {
      color: palette.services.broadband,
      fontWeight: typography.fontWeightBold,
    },
  })
);

export { useStyles };
