import makeStyles from '@material-ui/core/styles/makeStyles';

import { Typography } from '@material-ui/core';
import { formatPrice } from 'app/redux/utils/formatter';
import clsx from 'classnames';
import { price } from 'app/constants/propTypes';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(0, 0.5),
    flexDirection: 'row',
    alignItems: 'baseline',
    textAlign: 'right',
  },
  responsiveContainer: {
    display: 'flex',
    gap: theme.spacing(0, 0.5),
    flexDirection: 'column',
    alignItems: 'end',
    textAlign: 'right',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'baseline',
    },
  },
  price: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 22,
    lineHeight: 0.8,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
  },
  discountPrice: {
    color: theme.palette.success.main,
  },
  description: {
    lineHeight: 0.8,
  },
  asterisk: {
    fontSize: 22,
    fontWeight: 600,
  },
  strikethough: {
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'line-through',
    textDecorationColor: theme.palette.success.main,
    paddingRight: theme.spacing(0.5),
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

const PriceWithDiscount = ({
  price,
  discountedPrice,
  estimated,
  hideDesc,
  responsive,
  hasBbTbybPromo,
  includeStrikethrough,
}) => {
  const classes = useStyles();

  return (
    <div
      className={responsive ? classes.responsiveContainer : classes.container}
    >
      <div>
        {estimated && <Typography component={'span'}>from </Typography>}
        {includeStrikethrough && discountedPrice && (
          <Typography className={classes.strikethough} component="span">
            {formatPrice(price)}
          </Typography>
        )}
        <Typography
          className={clsx(classes.price, {
            [classes.discountPrice]: hasBbTbybPromo,
          })}
          component="span"
        >
          {formatPrice(discountedPrice ?? price)}
          {hasBbTbybPromo && (
            <>
              <Typography component="span" className={classes.text}>
                {' '}
                for 6 months,
              </Typography>
              <Typography className={classes.text}>
                then {formatPrice(price)} for 12 months
              </Typography>
            </>
          )}
        </Typography>
      </div>
      {!hideDesc && !hasBbTbybPromo && (
        <Typography component="span" className={classes.description}>
          {' '}
          a month
        </Typography>
      )}
    </div>
  );
};

PriceWithDiscount.propTypes = {
  price: price.isRequired,
  discountedPrice: price,
  estimated: PropTypes.bool,
  hideDesc: PropTypes.bool,
  responsive: PropTypes.bool,
  includeStrikethrough: PropTypes.bool,
  hasBbTbybPromo: PropTypes.bool,
};

export default PriceWithDiscount;
