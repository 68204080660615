import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { Link } from '@material-ui/core';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { FREE_ENERGY_CLUB_TERMS_URL } from 'app/config/externalLinks';

const FreeEnergyBanner = ({ classes = {}, legalBitExpanded = false }) => {
  const internalClasses = useStyles();

  return (
    <Paper
      classes={{
        root: clsx(internalClasses.root, classes.root),
      }}
    >
      <div className={internalClasses.textContainer}>
        <Typography variant="h3" className={internalClasses.heading}>
          Limited time offer -{' '}
          <span className={internalClasses.highlight}>£100 OFF</span>
        </Typography>
        <Typography component="p" className={internalClasses.text}>
          Bundle any three services and you'll get{' '}
          <span className={internalClasses.highlight}>£100 off your bill</span>
          {legalBitExpanded && '*'}
        </Typography>
        {!legalBitExpanded && (
          <Link
            href={FREE_ENERGY_CLUB_TERMS_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={internalClasses.termsLink}
          >
            <Typography>Terms apply</Typography>
          </Link>
        )}{' '}
      </div>
      <img
        src="images/banners/moneySavings.svg"
        alt="Wallet icon"
        className={internalClasses.image}
      />
    </Paper>
  );
};

FreeEnergyBanner.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  legalBitExpanded: PropTypes.bool,
};

export { FreeEnergyBanner };
